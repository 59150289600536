import { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg';
import { Card, CardContent, CardTitle } from '@/shadcn/ui/card';

type Props = {
  title: string;
  linkTo?: string;
  iconSrc?: string;
  content?: ReactNode;
  contentProps?: {
    text?: 'default' | 'muted' | 'destructive';
    size?: 'default' | '5xl';
  };
  doubleHeightContent?: ReactNode;
  fullWidth?: boolean;
};

export const Widget = ({
  title,
  linkTo,
  iconSrc,
  content,
  contentProps: { text = 'default', size = 'default' } = {},
  doubleHeightContent,
  fullWidth = false,
}: Props) => {
  let card = (
    <Card className={doubleHeightContent ? 'h-64' : 'h-30'}>
      <CardTitle className="p-4 capitalize font-figtree text-muted flex justify-between items-center">
        {title}
        {linkTo && <img src={ArrowIcon} alt="arrow icon" width={16} height={16} />}
      </CardTitle>
      {content && (
        <CardContent
          className={`p-4 flex justify-between items-end gap-6 text-${text} ${size === 'default' ? 'font-figtree' : 'text-5xl'}`}
        >
          {content}
          {iconSrc && <img src={iconSrc} alt="card icon" width={24} height={24} />}
        </CardContent>
      )}
      {doubleHeightContent && <div className="p-4 pt-6">{doubleHeightContent}</div>}
    </Card>
  );

  if (linkTo) {
    card = <Link to={linkTo}>{card}</Link>;
  }

  return <div className={fullWidth ? 'w-full' : 'min-w-52'}>{card}</div>;
};
