import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { FIREBASE_WEB_CONFIG } from '@/core.const';

export const bootstrapFirebase = () => {
  const firebaseConfig = JSON.parse(FIREBASE_WEB_CONFIG);
  const firebaseApp = initializeApp(firebaseConfig);

  getAuth(firebaseApp);
};
