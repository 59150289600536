import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const bootstrapSentry = () => {
  const isSentrySetup = Boolean(
    import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGET,
  );

  // if (!isSentrySetup && import.meta.env.PROD) {
  //   throw new Error(
  //     'VITE_SENTRY_DSN and VITE_SENTRY_TRACE_PROPAGATION_TARGET must be set in .env.production',
  //   );
  // }

  if (isSentrySetup) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1,
      tracePropagationTargets: [import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGET],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });
  }
};
