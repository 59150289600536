import { useEffect } from 'react';

import { Button } from '@/shadcn/ui/button';

type Props = { error: unknown; resetError: () => void };

export const ErrorFallback = ({ error, resetError }: Props) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <>
      there was an error
      <Button onClick={resetError} />
    </>
  );
};
