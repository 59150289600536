import ChargerIcon from '@/assets/icons/charger.svg';
import LocationsIcon from '@/assets/icons/locations.svg';
import { LoadingSpinner } from '@/components/loader.tsx';
import { Widget } from '@/components/widget.tsx';
import { isDefined } from '@/lib/utils';
import { useLocationStatistics } from '@/pages/locations/hooks/use-location-statistic.query.ts';

export const LocationsStatistic = () => {
  const { data: statistics, isLoading } = useLocationStatistics();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isDefined(statistics)) {
    return null;
  }

  return (
    <div className="flex justify-start gap-4 pb-4">
      <Widget
        title="Locations"
        content={statistics.chargeSitesCount}
        iconSrc={LocationsIcon}
        contentProps={{ size: '5xl' }}
      />

      <Widget
        title="Chargers"
        content={statistics.chargePointsCount}
        iconSrc={ChargerIcon}
        contentProps={{ size: '5xl' }}
      />
    </div>
  );
};
