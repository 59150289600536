import { LoadingSpinner } from '@/components/loader.tsx';
import { LocationTable } from '@/components/location-table';
import { LocationsEmpty } from '@/pages/locations/components/locations-empty.tsx';
import { LocationsStatistic } from '@/pages/locations/components/locations-statistic.tsx';
import { useLocationList } from '@/pages/locations/hooks/use-location-list.query.ts';

export const LocationsPage = () => {
  const { data, isLoading, error } = useLocationList({
    page: 1,
    take: 100,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data || data.totalItems < 0) {
    return <LocationsEmpty />;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="flex-col">
      <LocationsStatistic />
      <LocationTable data={data.items} />
    </div>
  );
};
