import { LatLng } from 'leaflet';

import { ConnectorTypeEnumSchema } from './open-api/_api';

export const BASE_BE_URL = import.meta.env.VITE_BASE_BACKEND_URL as string;
export const FIREBASE_WEB_CONFIG = import.meta.env.VITE_FIREBASE_WEB_CONFIG as string;

export const MAP_START_POSITION: Pick<LatLng, 'lat' | 'lng'> = {
  lat: Number.parseFloat(import.meta.env.VITE_MAP_START_POSITION_LAT || '52.23149341277748'),
  lng: Number.parseFloat(import.meta.env.VITE_MAP_START_POSITION_LNG || '21.00668361916234'),
};
export const MAP_SOURCE_URL =
  import.meta.env.VITE_MAP_SOURCE_URL || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const MAP_SELECT_DISTANCE_KM =
  Number.parseFloat(import.meta.env.VITE_MAP_SELECT_DISTANCE_KM) || 0;

export const CONNECTOR_TYPES = ConnectorTypeEnumSchema['enum'][0];
