import { MapContainer, TileLayer } from 'react-leaflet';

import { MAP_SOURCE_URL, MAP_START_POSITION } from '@/core.const';
import { Card } from '@/shadcn/ui/card';

export const MapComponent = () => {
  return (
    <Card className="h-full w-full overflow-hidden">
      <MapContainer zoom={12} center={MAP_START_POSITION} className="w-full h-full">
        <TileLayer
          url={MAP_SOURCE_URL || ''}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          tileSize={512}
          zoomOffset={-1}
        />
      </MapContainer>
    </Card>
  );
};
