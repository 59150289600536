import { Navigate, useSearchParams } from 'react-router-dom';

import ConfirmationEmailIcon from '../../assets/icons/confirmation-email.svg';

export const EmailLinkSentPage = () => {
  const [params] = useSearchParams();
  const email = params.get('email');
  const emailForSignIn = window.localStorage.getItem('emailForSignIn');

  if (!email?.length || !emailForSignIn || email !== emailForSignIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="w-[512px] h-[408px] rounded-lg shadow-lg border-0 bg-background">
      <div className="pt-16">
        <div className="flex justify-center">
          <img src={ConfirmationEmailIcon} alt="Confirmation Email Icon" className="h-112 w-112" />
        </div>

        <h1 className="text-xl mt-12">Confirm your email address</h1>

        <div className="text-xs text-muted mt-5">Tap the button in the email that we sent to</div>
        <div className="text-xs text-link">{email}</div>
      </div>
    </div>
  );
};
