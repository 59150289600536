import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg';
import ConnectorCCSIcon from '@/assets/icons/connectors/ccs.svg';
import ConnectorType1Icon from '@/assets/icons/connectors/type-1.svg';
import ConnectorType2Icon from '@/assets/icons/connectors/type-2.svg';
import { CONNECTOR_TYPES } from '@/core.const';
import { fromWattToKiloWatt } from '@/lib/utils';
import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';
import { Table, TableBody, TableCell, TableRow } from '@/shadcn/ui/table';

type ChargePoint = CpmsChargeSiteListResponseDto['items'][number]['chargePoints'][number];

const connectorTypeIconsMap: Record<number, string> = {
  [CONNECTOR_TYPES.CCS]: ConnectorCCSIcon,
  [CONNECTOR_TYPES.TYPE1]: ConnectorType1Icon,
  [CONNECTOR_TYPES.TYPE2]: ConnectorType2Icon,
};

const columns: ColumnDef<ChargePoint>[] = [
  {
    accessorKey: 'title',
    cell: (info) => (
      <span className="font-figtree font-text-sm pl-4">{info.row.original.title}</span>
    ),
  },
  {
    accessorKey: 'power',
    cell: (info) => {
      const power =
        info.row.original.maxConnectorPowerWt === info.row.original.minConnectorPowerWt
          ? `${fromWattToKiloWatt(info.row.original.minConnectorPowerWt)} kW`
          : `${fromWattToKiloWatt(info.row.original.minConnectorPowerWt)} - ${fromWattToKiloWatt(info.row.original.maxConnectorPowerWt)} kW`;

      return <span className="font-medium text-lg">{power}</span>;
    },
  },
  {
    accessorKey: 'connectors',
    cell: (info) =>
      info.row.original.connectorTypes.map((connectorType) => (
        <img key={connectorType} src={connectorTypeIconsMap[connectorType]} alt="connecotr icon" />
      )),
  },
  {
    accessorKey: 'actions',
    cell: () => <img src={ArrowIcon} alt="arrow right icon" className="p-3 ml-auto mr-2.5" />,
  },
];

type Props = {
  data: ChargePoint[];
  standalone?: boolean;
};

export const ChargePointTable = ({ data, standalone = false }: Props) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleNavigateCharegPoint = (chargePointId: number) => {
    navigate(`/charge-point/${chargePointId}`);
  };

  return (
    <Table>
      <TableBody
        className={`relative z-10 before:-z-10 before:absolute before:inset-0 before:bg-background-muted ${standalone ? 'before:rounded-md [&>*:nth-child(even)]:bg-background' : 'before:rounded-b-md [&>*:nth-child(odd)]:bg-background'}`}
      >
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            data-state={row.getIsSelected() && 'selected'}
            className={`cursor-pointer before:absolute before:inset-0 before:border-2 before:border-borderLight ${standalone ? 'before:rounded-md' : 'before:rounded-b-md'}`}
            onClick={() => handleNavigateCharegPoint(row.original.id)}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
