import { useQuery } from '@tanstack/react-query';

import { getCpmsChargeSiteStatistics } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';

export const useLocationStatistics = () => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_STATISTIC],
    queryFn: () => getCpmsChargeSiteStatistics({ throwOnError: true }).then(({ data }) => data),
  });
};
