import { createContext, useContext } from 'react';

type UserContextType = {
  isFirebaseLoaded: boolean;
  isUserLoggedIn: boolean;
  isPrivateRoutesAllowed: boolean;
};

export const UserContext = createContext<UserContextType>({
  isFirebaseLoaded: false,
  isUserLoggedIn: false,
  isPrivateRoutesAllowed: false,
});

export const useUserContext = () => useContext(UserContext);
