import { ReactNode } from 'react';

import { getAuth } from 'firebase/auth';
import { Link, useLocation } from 'react-router-dom';

import { cc } from '@/lib/utils';
import { Avatar, AvatarImage } from '@/shadcn/ui/avatar';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from '@/shadcn/ui/sidebar';

import AvatarIcon from '../../assets/icons/avatar.svg';
import BillingIcon from '../../assets/icons/billing.svg';
import DashboardIcon from '../../assets/icons/dashboard.svg';
import LocationsIcon from '../../assets/icons/locations.svg';
import TariffsIcon from '../../assets/icons/tariffs.svg';
import UserIcon from '../../assets/icons/user.svg';
import IonLogo from '../../assets/logos/ion.svg';

type Props = {
  children: ReactNode;
};

const items = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: () => <img src={DashboardIcon} alt="Dashboard icon" />,
  },
  {
    title: 'Locations',
    url: '/locations',
    icon: () => <img src={LocationsIcon} alt="Locations icon" />,
  },
  {
    title: 'Tariffs',
    url: '/tariffs',
    icon: () => <img src={TariffsIcon} alt="Tariffs icon" />,
  },
  {
    title: 'Billing',
    url: '/billing',
    icon: () => <img src={BillingIcon} alt="Billing icon" />,
  },
  {
    title: 'Users',
    url: '/users',
    icon: () => <img src={UserIcon} alt="User icon" />,
  },
];

export const SidebarLayout = ({ children }: Props) => {
  const location = useLocation();

  const isCurrentRoute = (url: string) => location.pathname.startsWith(url);

  return (
    <SidebarProvider>
      <Sidebar variant="inset">
        <SidebarHeader>
          <Avatar>
            <AvatarImage src={AvatarIcon} alt="Avatar icon" />
          </Avatar>
          <span className="overflow-hidden text-ellipsis whitespace-nowrap font-medium">
            {getAuth().currentUser?.email ?? getAuth().currentUser?.phoneNumber ?? 'User'}
          </span>
        </SidebarHeader>
        <SidebarContent>
          <SidebarMenu>
            {items.map((item) => (
              <SidebarMenuItem
                key={item.title}
                className={cc(isCurrentRoute(item.url), 'bg-black text-background')}
              >
                <SidebarMenuButton asChild>
                  <Link to={item.url}>
                    <item.icon />
                    <span className="leading-4">{item.title}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarContent>
        <SidebarFooter>
          <img src={IonLogo} alt="ION logo" width={32} height={32} />
        </SidebarFooter>
      </Sidebar>
      <SidebarInset>
        <div className="py-9 pl-9">
          <h1 className="text-3xl font-medium capitalize">{location.pathname.split('/')[1]}</h1>
        </div>
        <div className="h-fit w-full px-6 pt-1">{children}</div>
      </SidebarInset>
    </SidebarProvider>
  );
};
