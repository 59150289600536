import * as Sentry from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { AuthProvider } from '@/common/auth/auth-provider.tsx';

import { ErrorFallback } from './error-fallback';

export const AppReadyWrapper = () => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          // TODO: refactor this, something wrong
          // eslint-disable-next-line sonarjs/no-unstable-nested-components
          fallback={({ error, resetError }) => (
            <ErrorFallback
              error={error}
              resetError={() => {
                reset();
                resetError();
              }}
            />
          )}
        >
          <AuthProvider>
            <Outlet />
          </AuthProvider>
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
