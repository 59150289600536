import { MapComponent } from '@/components/map';
import { Widget } from '@/components/widget';

export const DashboardPage = () => {
  return (
    <>
      <div className="h-96 mb-4">
        <MapComponent />
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col gap-4 mb-4 w-full">
          <div className="flex gap-4">
            <Widget
              fullWidth
              title="Successful Sessions, 30 d"
              content={'0'}
              linkTo="#"
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title="Average Success Rate"
              content={'0%'}
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title="Average Utilization Rate"
              content={'0%'}
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title="Network Uptime"
              content={'0%'}
              contentProps={{ size: '5xl' }}
            />
          </div>
          <div className="flex gap-4">
            <Widget
              title="Total Revenue Generated 30 d, AED"
              fullWidth
              doubleHeightContent
              contentProps={{ size: '5xl' }}
            />
            <Widget
              title="Average Session Duration Last 30 d"
              fullWidth
              doubleHeightContent
              contentProps={{ size: '5xl' }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 w-1/4">
          <Widget
            title="Failed Charging Sessions, Today"
            fullWidth
            content={'0'}
            contentProps={{ size: '5xl' }}
            doubleHeightContent
          />
          <Widget
            title="Maintenance Tasks"
            fullWidth
            content={'0'}
            contentProps={{ size: '5xl' }}
          />
        </div>
      </div>
    </>
  );
};
