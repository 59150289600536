import { getAuth } from 'firebase/auth';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import LoginIonLogo from '../../assets/logos/login-ion-logo.svg';

export const LoginLayout = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') ?? '/dashboard';

  if (getAuth().currentUser) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <div className="bg-background-muted min-h-screen flex flex-col items-center">
      <div className="absolute top-16">
        <img src={LoginIonLogo} alt="Logo" />
      </div>
      <div className="mt-40 text-center">{<Outlet />}</div>
      <div className="absolute bottom-16 text-xs text-link">
        {/*@todo fix it when the design is ready.*/}
        <a href="/privacy" className="hover:underline">
          Privacy Policy
        </a>{' '}
        /{' '}
        <a href="/terms" className="hover:underline">
          Terms of Service
        </a>
      </div>
    </div>
  );
};
