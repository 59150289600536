import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { SidebarLayout } from '@/common/root/sidebar-layout.tsx';
import { useUserContext } from '@/contexts/user/user.context';

export const PrivateRoutesAllowedWrapper = () => {
  const { isFirebaseLoaded, isUserLoggedIn, isPrivateRoutesAllowed } = useUserContext();
  const location = useLocation();

  if (isFirebaseLoaded && !isPrivateRoutesAllowed) {
    const redirectTo = location.pathname + location.search;

    return <Navigate to={`/login?${new URLSearchParams({ redirectTo })}`} />;
  }

  return isUserLoggedIn && isPrivateRoutesAllowed ? (
    <SidebarLayout>
      <Outlet />
    </SidebarLayout>
  ) : (
    <Navigate to="/login" />
  );
};
