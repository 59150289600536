import { Link } from 'react-router-dom';

import GeolocationIcon from '@/assets/icons/geolocation.svg';
import LocationsIcon from '@/assets/icons/locations.svg';
import { Widget } from '@/components/widget.tsx';
import { Button } from '@/shadcn/ui/button.tsx';

export const LocationsEmpty = () => {
  return (
    <div className="flex-col">
      <div className="flex justify-start">
        <Widget
          title="Locations"
          content={'0'}
          iconSrc={LocationsIcon}
          contentProps={{ size: '5xl' }}
        />
      </div>
      <div className="flex flex-col items-center justify-center h-full my-16 gap-6">
        <img src={GeolocationIcon} alt="GeolocationIcon Icon" />

        <Link to="/locations/add">
          <Button variant="secondary">Add Location</Button>
        </Link>
      </div>
    </div>
  );
};
