import { useEffect } from 'react';

import { getAuth } from 'firebase/auth';
import { useSearchParams } from 'react-router-dom';

import ConfirmationEmailIcon from '../../assets/icons/confirmation-email.svg';

export const UserNotInvitedPagePage = () => {
  const [params] = useSearchParams();
  const email = params.get('email') ?? 'email';

  useEffect(() => {
    getAuth()
      .signOut()
      .then(() => {
        console.log('User signed out');
        window.localStorage.removeItem('emailForSignIn');
      });
  }, []);

  //@todo fix it when the design is ready
  return (
    <div className="w-[512px] h-[408px] rounded-lg shadow-lg border-0 bg-background">
      <div className="pt-16">
        <div className="flex justify-center">
          <img src={ConfirmationEmailIcon} alt="Confirmation Icon" className="h-112 w-112" />
        </div>

        <div className="text-destructive text-m mt-12">
          The <span className="text-link">{email}</span> you using, is not invited to the system
          yet. Please request that your admin add you as an operator first.
        </div>

        <div className="text-xs text-muted mt-5">
          If you think it&apos;s a mistake please contact us.
        </div>
      </div>
    </div>
  );
};
