import { QueryClient } from '@tanstack/react-query';

import {
  isForbiddenError,
  isInternalServerError,
  isNotFoundError,
  isUnauthorizedError,
} from '../open-api/http.util';

const shouldThrowOnError = (error: unknown) => {
  return (
    isNotFoundError(error) ||
    isUnauthorizedError(error) ||
    isForbiddenError(error) ||
    isInternalServerError(error)
  );
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      throwOnError: shouldThrowOnError,
      retry: 1,
    },
    mutations: {
      throwOnError: shouldThrowOnError,
    },
  },
});
