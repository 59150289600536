import { SVGProps } from 'react';

import { cn } from '@/lib/utils';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  className?: string;
}

//todo: default spinner component, replace after design is ready
export const LoadingSpinner = ({ size = 46, className, ...props }: Props) => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn('animate-spin', className)}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  </div>
);
