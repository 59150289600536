import { getAuth } from 'firebase/auth';

import { BASE_BE_URL } from '../core.const';
import { client } from './_api';
import { HttpException } from './http.exception';

export const bootstrapOpenApiClient = () => {
  client.setConfig({
    baseUrl: BASE_BE_URL,
    throwOnError: true,
  });

  client.interceptors.request.use(async (request) => {
    const token = await getAuth().currentUser?.getIdToken();

    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    return request;
  });

  client.interceptors.response.use(async (response) => {
    if (!response.ok) {
      const jsonRes = await response.json();

      throw new HttpException(response.status, jsonRes);
    }

    return response;
  });
};
