import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import GearIcon from '@/assets/icons/gear.svg';
import { LoginLayout } from '@/common/root/login-layout.tsx';
import { PrivateRoutesAllowedWrapper } from '@/common/root/private-routes-allowed-wrapper.tsx';
import { LocationsPage } from '@/pages/locations/locations-page.tsx';
import { EmailLinkLoginPage } from '@/pages/login/email-link-login-page.tsx';
import { EmailLinkSentPage } from '@/pages/login/email-link-sent-page.tsx';
import { UserNotInvitedPagePage } from '@/pages/login/user-not-invited-page.tsx';

import { AppReadyWrapper } from './common/root/app-ready-wrapper';
import { DashboardPage } from './pages/dashboard/dashboard-page';
import { Button } from './shadcn/ui/button';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <AppReadyWrapper />,
    children: [
      {
        path: '/',
        element: <PrivateRoutesAllowedWrapper />,
        children: [
          {
            path: '/dashboard',
            element: <DashboardPage />,
          },
          {
            path: '/locations',
            element: <LocationsPage />,
          },
          {
            path: '/tariffs',
            element: (
              <>
                <Button size="fullWidth">full width</Button>
                <br />
                <br />
                <br />
                <Button variant="secondary">Witaj Świecie</Button>
                <br />
                <br />
                <br />
                <Button variant="destructive">hello world</Button>
                <br />
                <br />
                <br />
                <Button variant="ghost">привіт світ</Button>
                <br />
                <br />
                <br />
                <Button variant="outline">مرحبا بالعالم</Button>
                <br />
                <br />
                <br />
                <Button variant="text">
                  Hola Mundo <img src={GearIcon} alt="gear icon" />
                </Button>
              </>
            ),
          },
          {
            path: '/billing',
            element: <>billing</>,
          },
          {
            path: '/users',
            element: <>users</>,
          },
        ],
      },
      {
        path: '/login',
        element: <LoginLayout />,
        children: [
          {
            path: '/login',
            element: <EmailLinkLoginPage />,
          },
          {
            path: '/login/sent',
            element: <EmailLinkSentPage />,
          },
          {
            path: '/login/not-invited',
            element: <UserNotInvitedPagePage />,
          },
        ],
      },
    ],
  },
]);
