import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { LoadingSpinner } from '@/components/loader';
import { BASE_BE_URL } from '@/core.const.ts';
import { Button } from '@/shadcn/ui/button.tsx';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shadcn/ui/card';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/shadcn/ui/form.tsx';
import { Input } from '@/shadcn/ui/input.tsx';

const FormSchema = z.object({
  email: z.string().email({ message: 'Please use valid email' }),
});

type FormSchemaType = z.infer<typeof FormSchema>;

export const EmailLinkLoginPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const redirectTo = new URLSearchParams(location.search).get('redirectTo') ?? '/dashboard';

  useEffect(() => {
    const href = window.location.href;
    const email = window.localStorage.getItem('emailForSignIn');

    if (!email) {
      setIsLoading(false);

      return;
    }

    if (email && !isSignInWithEmailLink(getAuth(), href)) {
      console.error('Email or link is not valid');
      setIsLoading(false);

      return;
    }

    signInWithEmailLink(getAuth(), email, href)
      .then(() => {
        navigate(redirectTo);
      })
      .catch((error) => {
        console.error('User not found or link is wrong', error);
        navigate('/login');
      })
      .finally(() => {
        window.localStorage.removeItem('emailForSignIn');
        setIsLoading(false);
      });
  }, [navigate, redirectTo]);

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: { email: '' },
  });

  const onSubmit = async ({ email }: FormSchemaType) => {
    try {
      await sendSignInLinkToEmail(getAuth(), email, {
        url: `${BASE_BE_URL}/auth/verify-email-link?${new URLSearchParams({ email, redirectTo })}`,
        handleCodeInApp: true,
      });
      window.localStorage.setItem('emailForSignIn', email);
      navigate(`/login/sent?${new URLSearchParams({ email })}`);
    } catch (error) {
      console.error('Failed to send the sign-in link. Please try again.', error);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Card className="w-[512px] h-[600px] rounded-lg shadow-lg border-0 bg-background">
      <CardHeader>
        <CardTitle className="text-xl mt-12 mb-5">Welcome to ION Charging Point OPS</CardTitle>
        <CardDescription className="text-sm text-muted">
          Let&apos;s get started with your email
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="mx-16">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="my-16">
                  <FormControl>
                    <Input
                      placeholder="Enter your email here"
                      {...field}
                      className={`h-14 ${
                        form.formState.errors.email ? 'border-red-500' : 'border-gray-300'
                      } focus:border-gray-400`}
                    />
                  </FormControl>
                  <div className="mt-2 h-2">
                    <FormMessage className="text-destructive text-left" />
                  </div>
                </FormItem>
              )}
            />
            <p className="text-xs my-16 text-muted">
              By pressing this button, I acknowledge and agree to the terms of the company.
            </p>
            <Button type="submit" disabled={!form.formState.isValid} size="fullWidth">
              Send Magic Link
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
