import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shadcn/ui/accordion';
import { Table, TableBody, TableCell, TableRow } from '@/shadcn/ui/table';

import { ChargePointTable } from './charge-point-table';

type Location = CpmsChargeSiteListResponseDto['items'][number];

const columns: ColumnDef<Location>[] = [
  {
    accessorKey: 'title',
    cell: (info) => {
      return (
        <div className="pl-6">
          <p className="font-medium text-lg leading-7">{info.row.original.title}</p>
          <p className="leading-4 text-muted font-figtree text-xs">{info.row.original.address}</p>
        </div>
      );
    },
  },
  {
    accessorKey: 'changerCount',
    cell: (info) => {
      const chargePointsCount = info.row.original.chargePointsCount;

      return (
        <p className="text-lg text-muted p-4">
          {chargePointsCount} charger{chargePointsCount > 1 || chargePointsCount === 0 ? 's' : ''}
        </p>
      );
    },
  },
  {
    accessorKey: 'actions',
    cell: (info) =>
      info.row.original.chargePointsCount > 0 && (
        <AccordionTrigger
          className="flex items-center justify-center border rounded-sm border-primary-light"
          onClick={(event) => event.stopPropagation()}
        />
      ),
  },
];

type Props = {
  data: Location[];
};

export const LocationTable = ({ data }: Props) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      maxSize: 100,
    },
  });

  const handleNavigateToLocation = (locationId: number) => {
    navigate(`/location/${locationId}`);
  };

  return (
    <Accordion type="single" collapsible>
      <Table>
        {table.getRowModel().rows.map((row) => (
          <AccordionItem value={row.id} key={row.id} asChild>
            <>
              <TableBody className="relative z-10 before:absolute before:inset-0 before:bg-background-muted before:rounded-md before:-z-10">
                <TableRow
                  data-state={row.getIsSelected() && 'selected'}
                  className="cursor-pointer"
                  onClick={() => handleNavigateToLocation(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow className="relative h-4">
                  {row.original.chargePointsCount > 0 && (
                    <TableCell colSpan={columns.length}>
                      <AccordionContent className="before:absolute before:left-0 before:right-0 before:-top-4 before:h-4 before:bg-background-muted">
                        <ChargePointTable data={row.original.chargePoints} standalone={false} />
                      </AccordionContent>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </>
          </AccordionItem>
        ))}
      </Table>
    </Accordion>
  );
};
